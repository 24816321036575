import {Customer} from '../../../Organisms/Customers/Customers.types'
import {Comment} from '../../../Organisms/Feedback'
import {Plant} from '../../../Organisms/Plants/Plants.types'

import {LineItem} from './Invoice.types'

export interface Delivery {
  /**
   * in case there is a different customer-facing delivery number, e.g. BOL
   */
  customerDeliveryNumber?: null | string
  /**
   * Reference to the customer in the backend system
   */
  customerId: string
  customerDetails: Pick<Customer, 'customerId' | 'customerName' | 'customerNumber'>
  deliveryEvents: DeliveryEvents
  deliveryEventsAccuracy: DeliveryEventsAccuracy
  /**
   * Unique Identifier for the delivery/ticket/shipment s
   */
  deliveryId: string
  /**
   * business identifier of the delivery that is normally also printed on delivery note
   */
  deliveryNumber?: null | string
  /**
   * Status information of the delivery
   */
  deliveryStatus: DeliveryStatusTypes
  links?: Link[]
  /**
   * quanity loaded
   */
  loadQuantity?: number | null
  /**
   * unit of measurement
   */
  loadQuantityUom?: null | string
  /**
   * reference to the order
   */
  orderId?: null | string
  /**
   * reference to the order
   */
  orderNumber?: null | string

  overallProcessingStatus?: null | string

  totalGoodsMovement?: null | string
  /**
   * reference to the plant
   */
  plantId?: null | string
  /**
   * name of the plant
   */
  plantName?: null | string
  /**
   * The date the delivery is expected to be executed
   */
  shippingDate?: Date | string
  /**
   * Indicates if an delivery is delivered to the customer or picked-up by the customer
   */
  shippingType?: Shippings
  /**
   * reference to the order
   */
  truckId?: null | string
  /**
   * reference to the order
   */
  truckName?: null | string
  truckLicensePlate?: null | string
  modeOfTransport?: null | string
  approvalStatus?: string
  approval?: Approval
  comments?: Comment[]
  loadDetails?: Partial<LoadDetail>
  materialNumber: string
  materialDescription: string
  transportVehicleDetails?: TransportVehicleDetails
  ageOfConcrete?: number
  timeOnJobSite?: number
  unloadDetails?: {lat: string; lng: string}
  loadingNumber?: string
  plantDetails?: Plant
  qrCode?: string
  returnedDelivery?: ReturnedDelivery
  eticket?: {ageOfConcrete: number}
  /**
   * pricing information for ITA CEM legacy
   */
  pricing?: {
    currency: string
    netValue: number
    unitPrice: number
  }
  haulier?: Haulier
  isService?: boolean
  relatedDelivery?: Delivery
}

interface ReturnedDelivery {
  deliveryId: string
  quantity: number
  unit: string
}

interface LoadDetail {
  tareWeight: string
  netWeight: string
  grossWeight: string
  weightUom: string
}
export interface DeliveryEvents {
  arrived?: null | string
  loadFinished?: null | string
  loadStarted?: null | string
  unloadFinished?: null | string
  unloadStarted?: null | string
  deliveryNotePrinted?: null | string
  plantLeft?: null | string
  leftCustomer?: null | string
}

export interface DeliveryEventsAccuracy {
  loadStarted?: DeliveryEventTimeStampTypes
  loadFinished?: DeliveryEventTimeStampTypes
  arrived?: DeliveryEventTimeStampTypes
  unloadStarted?: DeliveryEventTimeStampTypes
  unloadFinished?: DeliveryEventTimeStampTypes
  deliveryNotePrinted?: DeliveryEventTimeStampTypes
  plantLeft?: DeliveryEventTimeStampTypes
  leftCustomer?: DeliveryEventTimeStampTypes
  batchStarted?: DeliveryEventTimeStampTypes
}

/**
 * Status information of the delivery
 */
export enum DeliveryStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InTransit = 'inTransit',
  Loaded = 'loaded',
  Loading = 'loading',
  Open = 'open',
  Unknown = 'unknown',
  Unloaded = 'unloaded',
  Unloading = 'unloading',
  WaitingForLoad = 'waitingForLoad',
  WaitingForUnload = 'waitingForUnload',
  Invoiced = 'invoiced',
  Returned = 'returned'
}

export enum DeliveryStatusType {
  cancelled = 'cancelled',
  completed = 'completed',
  inTransit = 'inTransit',
  loaded = 'loaded',
  loading = 'loading',
  open = 'open',
  unknown = 'unknown',
  unloaded = 'unloaded',
  unloading = 'unloading',
  waitingForLoad = 'waitingForLoad',
  waitingForUnload = 'waitingForUnload',
  invoiced = 'invoiced',
  returned = 'returned'
}
export type DeliveryStatusTypes = keyof typeof DeliveryStatusType

export enum DeliveryEventTimeStampType {
  default = 'default',
  actual = 'actual',
  calculated = 'calculated',
  unknown = 'unknown'
}

export type DeliveryEventTimeStampTypes = keyof typeof DeliveryEventTimeStampType

export enum LinkMedia {
  OctetStream = 'application/octet-stream',
  Pdf = 'application/pdf',
  JSON = 'application/json',
  JPEG = 'image/jpeg',
  Tif = 'image/tiff'
}
export type LinkMediaType =
  | 'application/octet-stream'
  | 'application/pdf'
  | 'application/json'
  | 'image/tiff'
  | 'image/jpeg'
export interface Link {
  /**
   * Link to the document
   */
  href: string
  /**
   * media type
   */
  media?: null | LinkMediaType
  /**
   * Type of the relationship to the reference
   */
  rel?: RelatedEntities
  /**
   * indicating the type of document content
   */
  type?: LinkType | null
}

/**
 * Type of the relationship to the reference
 */
export enum Rel {
  Documents = 'documents',
  Invoices = 'invoices',
  Deliveries = 'deliveries'
}
export enum RelatedEntity {
  documents = 'documents',
  invoices = 'invoices',
  deliveries = 'deliveries',
  sites = 'sites',
  plants = 'plants',
  projects = 'projects',
  'related-delivery' = 'related-delivery'
}
export type RelatedEntities = keyof typeof RelatedEntity

export enum LinkType {
  DeliveryNote = 'deliveryNote',
  ProofOfDelivery = 'proofOfDelivery',
  ImageProof = 'imageProof',
  Deliveries = 'deliveries',
  OrderConfirmation = 'orderConfirmation'
}

/**
 * Indicates if an delivery is delivered to the customer or picked-up by the customer
 */
export enum ShippingType {
  Collect = 'collect',
  Deliver = 'deliver'
}
export enum Shipping {
  collect = 'collect',
  deliver = 'deliver'
}
export type Shippings = keyof typeof Shipping

export interface Approver {
  userId: string
  email?: string
  eMail?: string
  name: string
  mobileNumber?: string
  lat: string
  lng: string
}

export interface Approval {
  id: string
  approvalDate: Date
  comment: string
  hasAttachments: boolean
  approver: Approver
}

export interface TransportVehicleDetails {
  driverName: string
  isExternal: boolean
  ownerID: string
  ownerName: string
  trailer: string
}

export type DeliveryWithLineItem =
  | (Delivery & Omit<LineItem, 'links' | 'materialNumber' | 'materialDescription'>)
  | LineItem
export type DeliveriesWithLineItems = DeliveryWithLineItem[]

export interface Haulier {
  haulierId: string
  haulierNr: string
  haulierName: string
  countryId: string
  street: string
  postalCode: string
  city: string
}
