import {useQuery} from '@tanstack/react-query'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {useFeaturesState} from '../../../../Organisms/Features'
import {RequestCanceller} from '../../../../util/action.helper'
import {Invoice, InvoiceCategories, LineItemTypes, PaymentExcludeReason} from '../Invoice.types'
import {invoicesIncludedInExpectedTotalAmount} from '../Invoice.utils'

export interface TotalAmountCriteriaInvoiceIds {
  type: 'invoices'
  invoices: Invoice[]
  expand?: 'onlinePaymentStatus'
  includeManualBillings?: boolean
}
export interface TotalAmountCriteriaFilters {
  type: 'filters'
  customerId: string
  startDate: string
  endDate: string
  status?: 'open' | 'partiallyPaid'
  projectId?: string
  siteId?: string
  invoiceNumber?: string
  expand?: 'onlinePaymentStatus'
  includeManualBillings?: boolean
}

export interface TotalAmount {
  totalGrossAmount: number
  totalNetAmount: number
  totalTaxAmount: number
  expectedTotalGrossAmount: number
  expectedTotalNetAmount: number
  expectedTotalTaxAmount: number
  customerReference: string
  selectedInvoices: InvoiceToPay[]
  excludedInvoices: ExcludedInvoice[]
  payAll?: boolean
  entry?: 'invoices' | 'payNowTab'
}

export interface InvoiceToPay {
  invoiceId: string
  amount: number
  invoiceCreationDate: Date | string
  invoiceType: InvoiceCategories
  businessLine: string
  orgUnitId: string
  invoiceLineItemNumbers: InvoiceLineItemToPay[]
}

export interface InvoiceLineItemToPay {
  itemNumber: string
  amount: number
  lineItemTypes: LineItemTypes[]
}

export interface ExcludedInvoice {
  invoiceId: string
  reason: PaymentExcludeReason
  grossAmount: number
}

export type TotalAmountCriteria = TotalAmountCriteriaInvoiceIds | TotalAmountCriteriaFilters
const TotalAmountCanceller = RequestCanceller()

export const useTotalAmount = (
  criteria: TotalAmountCriteria,
  enabled: boolean,
  refetchOnMount: boolean = true
) => {
  const {getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const excludeLegacyInvoiceFeature = getResolutionByNameAndConstraint(
    'ExcludeLegacySystemsFromPayments',
    normalized,
    'connectorId'
  )
  return useQuery(
    ['invoices-total-amount', {...criteria}],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {type, ...params} = criteria
      let request: any

      if (criteria.type === 'invoices') {
        const invoiceIds = invoicesIncludedInExpectedTotalAmount(
          criteria.invoices,
          excludeLegacyInvoiceFeature
        ).map((invoice) => invoice.invoiceId)
        if (invoiceIds.length === 0) {
          return {
            totalGrossAmount: 0,
            totalNetAmount: 0,
            totalTaxAmount: 0,
            expectedTotalGrossAmount: 0,
            expectedTotalNetAmount: 0,
            expectedTotalTaxAmount: 0,
            customerReference: '',
            selectedInvoices: [],
            excludedInvoices: []
          } as TotalAmount
        }

        request = {
          invoiceIds: invoiceIds.join(','),
          expand: params.expand,
          includeManualBillings: params.includeManualBillings
        }
      } else {
        request = {...params}
      }
      TotalAmountCanceller.cancel('Request canceled by the next request.')
      const requestConfig: AxiosRequestConfig = {
        params: request,
        cancelToken: TotalAmountCanceller.getToken()
      }
      const response: AxiosResponse<TotalAmount> = await api
        .get('/invoices/totalAmount', requestConfig)
        .catch((thrown: any) => {
          if (!TotalAmountCanceller.isCancel(thrown)) {
            throw thrown
          }
          return {} as AxiosResponse<TotalAmount>
        })
      if (response) {
        return response.data
      }
      return {
        totalGrossAmount: 0,
        totalNetAmount: 0,
        totalTaxAmount: 0,
        expectedTotalGrossAmount: 0,
        expectedTotalNetAmount: 0,
        expectedTotalTaxAmount: 0,
        customerReference: '',
        selectedInvoices: [],
        excludedInvoices: []
      } as TotalAmount
    },
    {
      refetchOnMount: refetchOnMount,
      enabled:
        (enabled && criteria.type === 'invoices' && !!criteria.invoices) ||
        (criteria.type === 'filters' &&
          !!criteria.customerId &&
          !!criteria.startDate &&
          !!criteria.endDate)
    }
  )
}

export const useOptimisticTotalAmount = (
  criteria: TotalAmountCriteria
): TotalAmount | undefined => {
  const {getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const excludeLegacyInvoiceFeature = getResolutionByNameAndConstraint(
    'ExcludeLegacySystemsFromPayments',
    normalized,
    'connectorId'
  )
  if (criteria.type === 'filters') return undefined

  return invoicesIncludedInExpectedTotalAmount(
    criteria.invoices,
    excludeLegacyInvoiceFeature
  ).reduce(
    (agg, invoice) => {
      const validLineItems = invoice.lineItems?.filter((item) => !item.isPaymentPending) || []
      if (!validLineItems.length) {
        return agg
      }

      const total = validLineItems.reduce(
        (sum, lineItem) => ({
          gross: sum.gross + (lineItem.grossAmount || 0),
          net: sum.net + (lineItem.netAmount || 0),
          tax: sum.tax + (lineItem.taxAmount || 0)
        }),
        {gross: 0, net: 0, tax: 0}
      )
      return {
        totalGrossAmount: agg.totalGrossAmount + total.gross,
        totalNetAmount: agg.totalNetAmount + total.net,
        totalTaxAmount: agg.totalTaxAmount + total.tax,
        expectedTotalGrossAmount: agg.totalGrossAmount + total.gross,
        expectedTotalNetAmount: agg.totalNetAmount + total.net,
        expectedTotalTaxAmount: agg.totalTaxAmount + total.tax,
        customerReference: agg.customerReference
          ? `${agg.customerReference},${invoice.invoiceNumber}`
          : invoice.invoiceNumber,
        selectedInvoices: [
          ...agg.selectedInvoices,
          {
            invoiceId: invoice.invoiceId,
            amount: total.gross,
            invoiceCreationDate: invoice.creationDate || '',
            invoiceType: invoice.type,
            businessLine: invoice.businessLine,
            orgUnitId: invoice.orgUnitId || '',
            invoiceLineItemNumbers: validLineItems.map((item) => ({
              itemNumber: item.itemNumber,
              amount: item.grossAmount || 0,
              lineItemTypes: item.lineItemTypes || []
            }))
          }
        ],
        excludedInvoices: []
      }
    },
    {
      totalGrossAmount: 0,
      totalNetAmount: 0,
      totalTaxAmount: 0,
      expectedTotalGrossAmount: 0,
      expectedTotalNetAmount: 0,
      expectedTotalTaxAmount: 0,
      customerReference: '',
      selectedInvoices: [],
      excludedInvoices: []
    } as TotalAmount
  )
}
