import {Typography} from '@hconnect/uikit'
import {t} from 'i18next'
import {SelectDropdown} from '../../../../Molecules/SelectDropdown/SelectDropdown'
import React from 'react'

const now = new Date().getFullYear()
const years = Array(now - 2019)
  .fill('')
  .map((v, i) => now - i)
const options: Year[] = years.map((year) => ({
  label: year.toString(),
  id: year
}))

export interface Year {
  label: string
  id: number
}
interface YearFilterType {
  year?: Year
  onDropdownChange: (year: Year) => void
}

export const CurrentYear = options[0]

const SelectItem: React.FC<{year: Year}> = ({year}) => (
  <Typography component="div" variant="body1" color="textPrimary">
    {year.label}
  </Typography>
)

export const YearFilter: React.FC<YearFilterType> = ({year, onDropdownChange}) => {
  return (
    <SelectDropdown
      data-test-id="year-filter-dropdown"
      onLight
      label={t('statements.selectYear')}
      options={options}
      renderItem={(item: Year) => <SelectItem year={item} />}
      stringifyItem={(item) => `${item?.label}`}
      selectedItem={year}
      identifierKey="id"
      onChange={onDropdownChange}
    />
  )
}
