import {trackEvent} from '@hconnect/common/logging/Analytics'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Link} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {v4 as uuidV4} from 'uuid'

import {Entity, EntityPage, getDocumentFileName} from '../../../../Organisms/Documents'
import {LinkMedia} from '../Delivery.types'
import {FinanceAnalytics, Invoice} from '../Invoice.types'
import {findInvoiceDocument} from '../Invoice.utils'

interface Type {
  invoice: Invoice
  t: TFunction
  isCustomerInvoiceNumber: boolean
  downloadDocument: (jobId: string, documentId: string, fileName: string) => void
  analytics: FinanceAnalytics
}
export const InvoiceNumber: React.FC<Type> = ({
  invoice,
  t,
  isCustomerInvoiceNumber,
  downloadDocument,
  analytics
}) => {
  const invoicePdf = findInvoiceDocument(invoice)
  const invoiceNumber =
    isCustomerInvoiceNumber && invoice.customerInvoiceNumber
      ? invoice.customerInvoiceNumber
      : invoice.invoiceNumber
  const documentId = invoicePdf ? invoicePdf.href.split('/')[1] : undefined
  const fileName = invoicePdf
    ? getDocumentFileName(EntityPage.invoices, Entity.invoice, invoicePdf.media, t, {
        entityNumber: invoiceNumber
      })
    : `${Entity.invoice}.${LinkMedia.Pdf}`
  return documentId ? (
    <Box display="flex" flexDirection="column">
      <Link
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          const jobId: string = uuidV4()
          trackEvent('hubDownloadSingle', {
            product: 'hub',
            jobId: jobId,
            downloadedItem: 'invoice',
            linkToDownloadedItem: invoicePdf?.href,
            customerId: analytics.customerId,
            customerName: analytics.customerName,
            entryPoint: 'Invoice List',
            filteredDateRange: {startDate: analytics.startDate, endDate: analytics.endDate},
            salesOrg: invoice.orgUnitId,
            issuedDate: invoice.invoiceDate,
            userId: analytics.userId,
            userCountry: analytics.userCountry,
            userRoles: analytics.userRoles,
            userIsInternal: analytics.userIsInternal,
            downloadedItemBusinessLine: invoice.businessLine
          })
          downloadDocument(jobId, documentId, fileName)
        }}
        target="_blank"
        color="primary"
        variant="body1"
      >
        <Box sx={{display: 'inline-flex', textDecoration: 'inherit'}} gap={1}>
          <PictureAsPdfIcon
            data-test-id={`pdficon-${invoice.invoiceId}`}
            fontSize="small"
            color="primary"
          />
          {invoiceNumber}
        </Box>
      </Link>
      {invoice.isFinanceAccountingInvoice && invoice.customerInvoiceNumber && (
        <Box display="flex">
          {t('invoiceDetail.externalRef', {externalReference: invoice.customerInvoiceNumber})}
        </Box>
      )}
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      {invoiceNumber}
      {invoice.isFinanceAccountingInvoice && invoice.customerInvoiceNumber && (
        <Box display="flex">
          {t('invoiceDetail.externalRef', {externalReference: invoice.customerInvoiceNumber})}
        </Box>
      )}
    </Box>
  )
}
