import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Delivery} from '../Delivery.types'
import {Invoice} from '../Invoice.types'
import {mergeDeliveriesWithLineItems} from '../Invoice.utils'

import {AccountParams} from './useAccountParams'

export interface UseDeliveriesByInvoiceIdType {
  accountParams: AccountParams | undefined
  invoiceId: string
  invoice?: Invoice
}
export const useDeliveriesByInvoiceId = ({
  accountParams,
  invoiceId,
  invoice
}: UseDeliveriesByInvoiceIdType) => {
  return useQuery(
    [
      'deliveries',
      {
        accountParams,
        invoiceId
      }
    ],
    async () => {
      const params = {
        ...accountParams,
        invoiceId,
        customInvoiceNumber: invoice?.customerInvoiceNumber,
        sortedBy: 'deliveryNumber desc'
      }
      const res: AxiosResponse<Delivery[]> = await api.get('/deliveries', {
        params
      })
      const deliveriesWithLineItems = invoice ? mergeDeliveriesWithLineItems(res.data, invoice) : []
      return {deliveries: res.data, deliveriesWithLineItems}
    },
    {
      enabled: Boolean(
        accountParams !== undefined &&
          (accountParams.customerId || accountParams.payerId) &&
          (invoiceId || invoice?.customerInvoiceNumber)
      )
    }
  )
}
