/* eslint-disable complexity */
import {Divider, Page, TitleNav, Typography} from '@hconnect/uikit'
import {Box, CircularProgress, Grid, Paper} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import FailedPayment from '../../../Assets/payments/failedPayment.svg'
import SuccessfulPayment from '../../../Assets/payments/successfulPayment.svg'
import {useQueryParamState} from '../../../Hooks/useQueryParamState'
import {
  ResponsiveHeight,
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../../Molecules/Responsive.utils'
import {usePaymentResult, useStatusPolling} from '../PayNow/hooks'

import {ComingUpNext} from './components/ComingUpNext'
import Content from '../../../Molecules/Content'

type QueryState = {
  payerId: string
  customerId: string
  paymentId?: string
  isPaymentCreated: boolean
}

const POLLING_INTERVAL = 4000

export const PaymentConfirmationPage: React.FC = () => {
  const {t} = useTranslation()
  const [{payerId, customerId, paymentId, isPaymentCreated}] = useQueryParamState<QueryState>({
    isPaymentCreated: false
  })
  const history = useHistory()
  const [interval, setRefetchInterval] = useState<number>(POLLING_INTERVAL)
  const [paymentRequest, setPaymentRequest] = useState<boolean>(true)

  const {
    data: paymentStatus,
    isError: pollingError,
    isFetching: pollingFetching
  } = useStatusPolling(interval, setRefetchInterval, isPaymentCreated ? paymentId : undefined)

  const {
    data: paymentResult,
    isError: resultError,
    isFetching: resultFetching
  } = usePaymentResult(setPaymentRequest, paymentStatus?.status, paymentStatus?.id)

  const {smallScreen, largeScreen} = useResponsiveBreakpoints()
  const {classes} = useResponsiveGridStyles()
  const height = ResponsiveHeight

  const onBackToTransactions = () => {
    history.push('/finance/transactions')
  }

  const isPaymentSuccessful =
    isPaymentCreated &&
    paymentResult &&
    (paymentStatus?.status === 'done' || paymentResult.inApproval)

  const transactionId = paymentResult?.documentNumber || paymentId

  const isFetching = pollingFetching || resultFetching || (isPaymentCreated && paymentRequest)
  const isError = pollingError || resultError

  const translationPrefix = isPaymentSuccessful ? 'successful' : 'failed'

  return (
    <Content>
      <Page
        data-test-id="payment-confirmation-page"
        boxed={false}
        variant={!largeScreen ? 'default' : 'withDetails'}
        {...(smallScreen ? {px: 0, py: 0} : {py: 0})}
      >
        <Box my={1.5}>
          <TitleNav
            title={t('paymentConfirmation.backToTransactions')}
            onClick={onBackToTransactions}
            aria-label="Back to transactions"
            data-test-id="navigate-to-transactions"
          />
        </Box>
        <Grid
          container
          columnSpacing={2}
          style={{
            position: 'relative',
            marginTop: 0,
            minHeight: height,
            overflowY: 'auto'
          }}
        >
          <Grid item xs={12} lg={isPaymentSuccessful ? 8 : 12}>
            <Paper
              elevation={4}
              aria-label="Payment confirmation"
              className={smallScreen ? '' : classes.gridItem}
              sx={{
                p: 4,
                ...(!smallScreen ? {height: ResponsiveHeight} : {minHeight: ResponsiveHeight})
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  minHeight: '100%'
                }}
              >
                {isFetching ? (
                  <CircularProgress />
                ) : isError ? (
                  <Typography color="error">{t('errorboundary.error')}</Typography>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    sx={{textAlign: 'center', maxWidth: 500}}
                  >
                    <img
                      src={isPaymentSuccessful ? SuccessfulPayment : FailedPayment}
                      width="316px"
                      alt="Successful payment"
                    />
                    <Typography
                      variant="h2"
                      style={{fontSize: 24}}
                      data-test-id="payment-confirmation-title"
                    >
                      {t(`paymentConfirmation.${translationPrefix}PaymentTitle`)}
                    </Typography>
                    <Typography
                      customColor="textPrimarySoft"
                      data-test-id="payment-confirmation-description"
                    >
                      {t(`paymentConfirmation.${translationPrefix}PaymentDescription`, {
                        paymentId: transactionId
                      })}
                    </Typography>
                    <Divider style={{width: '100%', maxWidth: 368}} color="soft" />
                    <Typography
                      customColor="textPrimarySoft"
                      style={{fontSize: 14}}
                      data-test-id="payment-confirmation-info"
                    >
                      {t(`paymentConfirmation.${translationPrefix}PaymentInfo`)}
                    </Typography>
                  </Box>
                )}
                {!largeScreen && isPaymentSuccessful && (
                  <Box sx={{mt: 4, width: '100%'}}>
                    <ComingUpNext customerId={customerId} payerId={payerId} />
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>

          {largeScreen && isPaymentSuccessful && (
            <Grid item lg={4}>
              <Paper
                elevation={4}
                aria-label="Payment confirmation"
                className={smallScreen ? '' : classes.gridItem}
                sx={{
                  p: 4,
                  ...(!smallScreen ? {height: ResponsiveHeight} : {minHeight: ResponsiveHeight})
                }}
              >
                <ComingUpNext customerId={customerId} payerId={payerId} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Page>
    </Content>
  )
}
