import assignIn from 'lodash/assignIn'
import get from 'lodash/get'
import keys from 'lodash/keys'

import {FeatureResolutionType} from '../../../Organisms/Features'

import {LookupSelectItem} from './Action.types'
import {isPaymentPending} from './components/InvoiceStatus'
import {DeliveriesWithLineItems, Delivery} from './Delivery.types'
import {AccountParams, ExcludedInvoice} from './hooks'
import {
  BreakdownLineItem,
  FluffyRel,
  Invoice,
  InvoiceCategory,
  InvoicesSumDispatch,
  InvoicesSumStateType,
  InvoiceStatus,
  NormalizedAggregate,
  PaymentExcludeReason,
  TotalAmountCalculationGroup
} from './Invoice.types'

export const isInvoiceExpandable = (invoice: Invoice): boolean => invoice.type === 'invoice'

export const addInvoiceToSum = (
  item: Invoice,
  invoicesAddedForPayments: InvoicesSumStateType,
  setInvoicesAddedForPayments: React.Dispatch<InvoicesSumDispatch>
) => {
  let invoiceIds = [...invoicesAddedForPayments.invoiceIds]
  let invoiceNumbers = [...invoicesAddedForPayments.invoiceNumbers]
  let sum = invoicesAddedForPayments.sum
  if (invoicesAddedForPayments.invoiceIds.includes(item.invoiceId)) {
    invoiceIds = invoiceIds.filter((invoiceId) => invoiceId !== item.invoiceId)
    invoiceNumbers = invoiceNumbers.filter((invoiceNumber) => invoiceNumber !== item.invoiceNumber)
    sum = sum - item.invoiceGrossValue
  } else {
    invoiceIds = [...invoiceIds, item.invoiceId]
    invoiceNumbers = [...invoiceNumbers, item.invoiceNumber]
    sum += item.invoiceGrossValue
  }
  setInvoicesAddedForPayments({sum, invoiceIds, invoiceNumbers})
}

export const addAllInvoicesToSum = (
  invoices: readonly Invoice[]
): [sum: number, invoiceIds: string[], invoiceNumbers: string[]] => {
  let sum = 0
  let invoiceIds: string[] = []
  let invoiceNumbers: string[] = []
  for (let i = 0; i < invoices.length; i++) {
    if (
      !['cancelled', 'closed'].includes(invoices[i].status) &&
      invoices[i].type !== InvoiceCategory.creditNote
    ) {
      sum += invoices[i].invoiceGrossValue
      invoiceIds = [...invoiceIds, invoices[i].invoiceId]
      invoiceNumbers = [...invoiceNumbers, invoices[i].invoiceNumber]
    }
  }
  return [sum, invoiceIds, invoiceNumbers]
}

export const findInvoiceDocument = (invoice: Invoice) => {
  if (invoice && 'links' in invoice && Array.isArray(invoice.links)) {
    return invoice.links.find((link) => link.rel === FluffyRel.Documents)
  }
  return undefined
}

export const mergeDeliveriesWithLineItems = (
  deliveries: Delivery[],
  invoice: Invoice
): DeliveriesWithLineItems =>
  invoice &&
  deliveries &&
  invoice.lineItems &&
  invoice.lineItems
    .filter(
      (lineItem) =>
        !Object.prototype.hasOwnProperty.call(lineItem, 'isTax') ||
        (Object.prototype.hasOwnProperty.call(lineItem, 'isTax') && lineItem.isTax === false)
    )
    .map((lineItem) => {
      const relatedDelivery = deliveries.find(
        (delivery) =>
          lineItem.links &&
          lineItem.links.find(
            (link) =>
              link.rel === 'deliveries' &&
              (link.href === `deliveries/${delivery.deliveryId}` ||
                link.href.startsWith(`deliveries/lookup?deliveryNumber=${delivery.deliveryNumber}`))
          )
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {links, ...lineItemWOLinks} = lineItem
      return relatedDelivery ? {...relatedDelivery, ...lineItemWOLinks} : {...lineItem}
    })

export const findDeliveryFromRelatedSurcharge = (
  deliveries: Delivery[],
  item: BreakdownLineItem
): Delivery | undefined => {
  const deliveryLink = item.links?.find((link) => link.rel === 'deliveries')
  const deliveryId = deliveryLink && deliveryLink.href ? deliveryLink.href.split('/')[1] : undefined
  const delivery = deliveryId ? deliveries.find((del) => del.deliveryId === deliveryId) : undefined
  return delivery
}

export const navigateToInvoiceDetails = (
  invoiceId: string,
  customerId: string,
  history: any,
  invoicesParams: AccountParams | undefined,
  event?: React.MouseEvent<HTMLButtonElement>
) => {
  event && event.stopPropagation()
  const queryParam = invoicesParams?.payerId
    ? `payerId=${invoicesParams?.payerId}`
    : `customerId=${invoicesParams?.customerId || customerId}`
  history.push(`/invoices/${invoiceId}?${queryParam}`)
}

export const lineItemAggregation = (invoice: Invoice) => {
  let items = [...invoice.materialItems, ...invoice.serviceItems, ...invoice.surchargeItems]

  if (!items || !items.length) {
    items = invoice.lineItems as BreakdownLineItem[]
  }

  return items.reduce<NormalizedAggregate>((agg, item) => {
    const key = item.materialNumber || item.materialDescription || ''
    if (!agg[key]) {
      agg[key] = {
        netAmount: item.netAmount || 0,
        invoiceCurrency: invoice.invoiceCurrency,
        materialDescription: item.materialDescription || ''
      }
    } else {
      agg[key].netAmount += item.netAmount || 0
    }
    return agg
  }, {})
}

type CriteriaType = {
  isLookupActive?: boolean
  startDate?: string
  endDate?: string
  accountParams?: AccountParams
  site?: any
  sortedBy?: string
  sortAscending?: boolean
  isHavingServicesOrSurcharges?: boolean
  filters?: any
  items?: Invoice[]
  invoiceNumber?: string
  type: 'invoiceList' | 'invoiceDetails'
  isExcludeCancelledInvoicesEnabled?: boolean
}

export const getCriteria = ({
  isLookupActive,
  startDate,
  endDate,
  accountParams,
  site,
  sortedBy,
  sortAscending,
  isHavingServicesOrSurcharges,
  filters,
  items,
  invoiceNumber,
  type,
  isExcludeCancelledInvoicesEnabled
}: CriteriaType) => {
  if (type === 'invoiceDetails') {
    return {
      ...accountParams,
      invoiceNumber,
      expand: 'itemBreakdown'
    }
  }
  return assignIn(
    {
      ...(!isLookupActive ? {startDate} : {}),
      ...(!isLookupActive ? {endDate} : {}),
      ...accountParams,
      ...(site ? {siteId: site.siteId} : {}),
      sortedBy: `${sortedBy} ${sortAscending ? 'asc' : 'desc'}`,
      ...(isHavingServicesOrSurcharges && {expand: 'itemBreakdown'}),
      ...(filters?.status?.status ? {status: filters.status.status.join(',')} : {}),
      ...(filters?.orgUnitIds ? {orgUnitIds: filters.orgUnitIds} : {}),
      ...(isExcludeCancelledInvoicesEnabled ? {excludeCancelledInvoices: true} : {})
    },
    ...keys(filters).map((filter: any) => {
      const filterContent = get(filters, filter, null)
      if (filter === 'lookup') {
        if (filterContent.entity === LookupSelectItem.PO) {
          return {poNumber: filterContent.value}
        }

        const invoiceNumber =
          filterContent.entity === LookupSelectItem.INVOICE
            ? filterContent.value
            : items?.length
              ? items[0].invoiceNumber
              : ''
        return {invoiceNumber}
      }

      if (filterContent && filter === 'site') {
        return {siteId: filterContent.siteId}
      }

      return filterContent &&
        !['customer', 'date', 'payer', 'status', 'supplierFilterNames'].includes(filter)
        ? {...(filterContent ? {[filter]: filterContent} : {})}
        : null
    })
  )
}

export const payableInvoices = (
  invoices: Invoice[],
  excludeLegacyInvoiceFeature: FeatureResolutionType
) =>
  invoices.filter(
    (invoice) =>
      ['open'].includes(invoice.status) &&
      ['invoice', 'debitNote'].includes(invoice.type) &&
      invoice.invoiceGrossValue > 0 &&
      !isPaymentPending(invoice) &&
      !isIdLegacy(invoice.invoiceId, excludeLegacyInvoiceFeature)
  )

export const invoicesIncludedInExpectedTotalAmount = (
  invoices: Invoice[],
  excludeLegacyInvoiceFeature: FeatureResolutionType
) =>
  invoices.filter(
    (invoice) =>
      [InvoiceStatus.open, InvoiceStatus.partiallyPaid].includes(invoice.status as InvoiceStatus) &&
      ['invoice', 'debitNote', 'manualBilling'].includes(invoice.type) &&
      invoice.invoiceGrossValue > 0 &&
      !isPaymentPending(invoice) &&
      !isIdLegacy(invoice.invoiceId, excludeLegacyInvoiceFeature)
  )

export const isIdLegacy = (
  id: string | undefined,
  excludeLegacyInvoiceFeature: FeatureResolutionType
) => (id ? excludeLegacyInvoiceFeature.enabled.includes(id.split('.')[0]) : false)

export const mapExcludedInvoices = (invoices: Invoice[]): ExcludedInvoice[] =>
  invoices
    .map((invoice) => ({
      grossAmount: invoice.invoiceGrossValue,
      invoiceId: invoice.invoiceId,
      reason: excludedInvoiceReason(invoice)
    }))
    .filter((excludedInvoice) => excludedInvoice.reason !== PaymentExcludeReason.Unknown)

export const excludedInvoiceReason = (invoice: Invoice): PaymentExcludeReason => {
  if (invoice.status === 'cancelled' || invoice.status === 'closed') {
    return PaymentExcludeReason.Closed
  }

  if (invoice.type === 'creditNote' || invoice.invoiceGrossValue <= 0) {
    return PaymentExcludeReason.CreditNote
  }

  if (isPaymentPending(invoice)) {
    return PaymentExcludeReason.PaymentPending
  }

  return PaymentExcludeReason.Unknown
}

export const excludedInvoicesTotalAmount = (excludedInvoices: ExcludedInvoice[]): number =>
  excludedInvoices.reduce(
    // don't sum up for creditNote invoices with negative grossAmount
    (amount, {grossAmount}) => amount + (grossAmount > 0 ? grossAmount : 0),
    0
  )

export const excludedInvoiceGroupByReason = (
  reason: PaymentExcludeReason
): TotalAmountCalculationGroup | undefined => {
  if (
    reason === PaymentExcludeReason.Closed ||
    reason === PaymentExcludeReason.PaymentPending ||
    reason === PaymentExcludeReason.CreditNote
  ) {
    return TotalAmountCalculationGroup.Excluded
  }
  if (
    reason === PaymentExcludeReason.NotInSalesArea ||
    reason === PaymentExcludeReason.PartiallyPaid
  ) {
    return TotalAmountCalculationGroup.Included
  }
  if (reason === PaymentExcludeReason.ClearingLimitExceeded) {
    return TotalAmountCalculationGroup.ClearingLimitExceeded
  }
  return undefined
}

export const excludedInvoicesByReason = (
  excludedInvoices: ExcludedInvoice[]
): {[key: string]: ExcludedInvoice[]} =>
  excludedInvoices.reduce((excludedInvoiceGroups, excludedInvoice) => {
    const excludedGroup = excludedInvoiceGroupByReason(excludedInvoice.reason)
    if (excludedGroup) {
      ;(excludedInvoiceGroups[excludedGroup] = excludedInvoiceGroups[excludedGroup] || []).push(
        excludedInvoice
      )
    }
    return excludedInvoiceGroups
  }, {})

export const findInvoiceSurchargeDeliveries = (invoices: Invoice[]) =>
  invoices
    .map((invoice) => ({
      invoiceId: invoice.invoiceId,
      deliveryIds: invoice.surchargeItems
        .map((surchargeItem) => {
          const deliveryLink = surchargeItem.links?.find((link) => link.rel === 'deliveries')
          if (!deliveryLink) {
            return ''
          }
          return deliveryLink.href.split('/')[1]
        })
        .filter((deliveryId) => !!deliveryId)
    }))
    ?.filter((surchargeItem) => surchargeItem.deliveryIds.length > 0)
